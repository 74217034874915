import revive_payload_client_7sBpre1V70 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qvVAOXqoSD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_l9d6dsPDNb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NbVGMDQPfl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_wMp6NKAGHh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_k0qYef7Afb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nM77iXLQHo from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.1/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import script_hHEPzAINpx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+turnstile@0.6.3/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import chunk_reload_client_YXJgsoBRQS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_7sBpre1V70,
  unhead_qvVAOXqoSD,
  router_l9d6dsPDNb,
  payload_client_NbVGMDQPfl,
  check_outdated_build_client_wMp6NKAGHh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k0qYef7Afb,
  plugin_nM77iXLQHo,
  script_hHEPzAINpx,
  chunk_reload_client_YXJgsoBRQS
]